
import { defineComponent } from "vue";
import InfoDialog from "@/components/info/InfoDialog.vue";
import mountComponentMixin from "@/mixins/MountComponentMixin";

export default defineComponent({
  components: {
    InfoDialog,
  },
  mixins: [mountComponentMixin],
  methods: {
    dialog() {
      return this.$refs.dialog as any;
    },
    open() {
      this.dialog().open();
    },
  },
});
